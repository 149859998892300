import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TopImage from "../container/topImageContainer"
import TopContent from "../container/topContentContainer"
import CallToAction from "../container/ctaContainer"
import TopNews from "../container/topNewsContainer"

const IndexPage = () => (
  <Layout>
    <Seo title="ワカチエ | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | wakachie" />
    <TopImage />
    <TopContent />
    <CallToAction />
    <TopNews />
  </Layout>
)

export default IndexPage
