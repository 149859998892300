import React from "react"
import { Link } from "gatsby"

const TopNews = () => {
  return (
    <div className="topNewsContainer">
      <div className="newsWrapper">
        <h3>News</h3>
        <div className="newsContent">
          <dl>
            <dt>2024.02.19</dt>
            <dd>ワカチエが大切にする「心理的安全性」と、ワカチエ最大の特徴である「かわいさ」についてご紹介するホワイトペーパー
            <Link to="/download/psychologicalsafety">匿名社員が会社を救う！？ 心理的安全性がヒーローを生む</Link> と 
            <Link to="/download/kawaii-character">「かわいさ」が会議を変える！仕事を変える！</Link>を公開しました。是非ご一読ください。
            </dd>
          </dl>
          <dl>
            <dt>2023.11.22</dt>
            <dd>JBpress World 2023 働き方イノベーション セミナーに登壇いたしました。</dd>
          </dl>
          <dl>
            <dt>2023.8.31</dt>
            <dd>
              料金体系を見直し、より手軽にお使いいただけるようになりました。<br />
              詳細は<Link to="/plan">料金プランページ</Link>をご覧ください。
            </dd>
          </dl>
          <dl>
            <dt>2023.03.14</dt>
            <dd>パーソルイノベーション株式会社様・株式会社シイエム・シイ様・弊社の3社共催セミナー「DX時代の組織力強化と業務改善のはじめ方」に弊社スタッフが登壇いたしました。</dd>
          </dl>
          <dl>
            <dt>2022.12.14</dt>
            <dd>プレジデント社オンラインセミナー『DX成功のカギとは』に弊社代表 齋藤が登壇いたしました。</dd>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default TopNews
