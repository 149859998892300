import React from "react"
import imgMainVisual from '../images/mainvisual_wakachiescreen.png';

const TopImage = () => {
  return (
    <div className="topImageContainer">
      <div className="mainVisual">
        <div className="text">
          <h2>
            気兼ねなく意見を言いあえる。
          </h2>
          <p>ワカチエはMicrosoft Teamsにて匿名でコミュニケーションできるツールです<br />
            心理的安全性を高め、コミュニケーションを活発化しましょう。</p>

        </div>
        <div className="img">
          <img src={imgMainVisual} className="screenShot" />
          
        </div>

      </div>
    </div>
  )
}

export default TopImage
