import React, { useEffect, useContext } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faUsers } from "@fortawesome/free-solid-svg-icons"
import screenShotGif from '../images/img_screenshot1.gif';
import picCommunication from '../images/img_top_picCommunication.png';
import { ObserverContext } from "../provider/IntersectionObserverProvider";
import { handleObserver } from '../utils/IntersectionObserver'

const TopContent = () => {
  const { toTargets, targets } = useContext(ObserverContext);

  useEffect(() => {
    handleObserver(targets)
  }, []);

  return (
    <section className="topContentContainer">
      <div className="top1News">
        <h3>
          NEWS
        </h3>
        <dl>
          <dt>2024.02.19</dt>
          <dd>
            <Link to="/download/psychologicalsafety">匿名社員が会社を救う！？ 心理的安全性がヒーローを生む</Link>と<Link to="/download/kawaii-character">「かわいさ」が会議を変える！仕事を変える！</Link>の2つのホワイトペーパーを公開しました。是非ご一読ください。
          </dd>
        </dl>
      </div>

      <div className="leadComment fade-in up" ref={toTargets}>
        <div className="leadWrapper">
          <h3>ワカチエは世代差や場所を超えて、気兼ねなくコミュニケーションができることを目指したWeb会議サポートツールです。</h3>
          <p>
            導入はカンタン。<br />
            お使いのMicrosoft  Teamsにてスグにお使いいただけます。<br />
            どんな相手とも気兼ねなく意見を言いあえる。<br />
            そんなコミュニケーションをしてみませんか？
          </p>
        </div>
      </div>

      <div className="feature avatar">
        <div className="featureWrapper rev">
          <div className="text fade-in right" ref={toTargets}>
            <h3>
              心通わせる。<br />
              かわいいアバターで感情を伝えよう。
            </h3>
            <p>
              ワカチエの魅力の一つは、そのかわいらしいアバターによる表現力です。<br />
              カメラを使わずに、これらの愛らしいアバターを通じて感情を共有できます。<br />
              顔出し不要でありながら、あなたの感情や反応を豊かに彩ります。<br />
              重苦しい会議も、リラックスして楽しみながら、より親密で感情豊かなコミュニケーションが可能になります。
            </p>
            <Link to="/feature" className="linkButton">機能・特徴</Link>
          </div>
          <div className="img fade-in left" ref={toTargets}>
            <img src={screenShotGif} className="screenShot" />
            <StaticImage src="../images/img_reaction_btn.png" className="reactionBtn" alt={""} />
          </div>
        </div>
      </div>

      <div className="feature anonymous">
        <div className="featureWrapper">
          <div className="text fade-in left" ref={toTargets}>
            <h3>
              声を上げやすい環境。<br />
              匿名で自由に発言。
            </h3>
            <p>
              ワカチエは、匿名でのコミュニケーションを可能にします。<br />
              役職や年齢の壁を越えて、自由に意見を交換できる安全な環境を提供します。<br />
              これにより、オープンで健全な議論が促進され、チーム全体のアイデア共有と協力が向上します。
            </p>
            <Link to="/feature" className="linkButton">機能・特徴</Link>
          </div>
          <div className="img fade-in right" ref={toTargets}>
          <StaticImage src="../images/img_stamp.png" className="stamp" alt={""} />
          <StaticImage src="../images/img_stamp_btn.png" className="stampBtn" alt={""} />
        </div>

        </div>
      </div>

      <div className="feature plan">
        <div className="featureWrapper rev">
          <div className="text fade-in right" ref={toTargets}>
            <h3>
              リーズナブルな価格で、<br />
              チームのコミュニケーションを革新。
            </h3>
            <p>
              ワカチエは、37,500円/20ユーザー/月～利用可能。<br />
              お求めやすい価格設定で、チームや組織に最適なコミュニケーションソリューションを実現します。<br />
              無駄な費用は削減し、効果的なコミュニケーションのための投資に集中できます。
            </p>
            <Link to="/plan" className="linkButton">料金プラン</Link>
          </div>
          <div className="img fade-in right" ref={toTargets}>
            <div className="planDetail">
              <dl>
                <dt>Lite</dt>
                <dd>
                  <p className="personCount">
                    <FontAwesomeIcon icon={faUsers} />
                    20人まで
                  </p>
                  <p className="price">￥37,500</p>
                  <p className="priceNotice">(税込￥41,250) / 月額 / 年次でのご契約</p>
                  <ul>
                    <li><FontAwesomeIcon icon={faCheck} />リアクションアバター</li>
                    <li><FontAwesomeIcon icon={faCheck} />匿名スタンプ</li>
                    <li><FontAwesomeIcon icon={faCheck} />匿名会議フィードバック</li>
                    <li><FontAwesomeIcon icon={faCheck} />投票・多数決</li>
                    <li><FontAwesomeIcon icon={faCheck} />ハラスメント通報</li>
                  </ul>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div className="feature scene">
        <div className="featureWrapper">
          <div className="text fade-in left" ref={toTargets}>
            <h3>
              想像を現実に。<br />
              ワカチエで可能になるコミュニケーションの形。
            </h3>
            <p>
              ワカチエが提供する新しいコミュニケーションのスタイルを、具体的なユースケースを通じてご紹介します。<br />
              ハイブリッドワークでのチームミーティング、多様な背景を持つメンバー間の効果的な意見交換、匿名でのフィードバックの実施など、様々なシチュエーションでの利用例をご紹介します。<br />
              あなたの職場でのコミュニケーションの課題を解決するヒントになるでしょう。
            </p>
            <Link to="/usecase" className="linkButton">利用シーン</Link>
          </div>
          <div className="img fade-in left" ref={toTargets}>
          <img src={picCommunication} className="pic" />
        </div>

        </div>
      </div>



    </section>
  )
}

export default TopContent
